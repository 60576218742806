import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';


@Injectable({
  providedIn: 'root'
})
export class APIGetFactoryService {

  apiURL :string = environment.apiEndPoint;

  constructor(public http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService) { }

  public simpleOneGet(tableName: string, data: any) {
    console.log(" simpleOneGet sess", this.storage.get('lb4b-token'))
    console.log(" simpleOneGet tableName", tableName,"controlMethod","data",data)
    if (this.storage.get('lb4b-token')&&this.storage.get('lb4b-token')!=null) {
      let t = atob(JSON.parse(this.storage.get('lb4b-token'))['access_token'].split('.')[1])
      console.log(JSON.parse(t))
      t = JSON.parse(t)["iss"].split("/")
      console.log(t)
      let token = JSON.parse(this.storage.get('lb4b-token')).access_token
      console.log("GETfactrToken", token)
      const headers = new HttpHeaders({
        'content-type': 'applicaton/json',
        'authorization': 'Bearer ' + token,
        'realmName': t[t.length - 1]
    })
    let options = { headers: headers };
    return this.http.post(`${this.apiURL}/${tableName}`, data, options)
  }
    return this.http.get(`${this.apiURL}/${tableName}`, { params: data })
  }
  public customGet(tableName: string, controlMethod: string, data: any) {
    console.log("sess", this.storage.get('lb4b-token'))
    console.log("tableName", tableName,"controlMethod",controlMethod,"data",data)
    
    if (this.storage.get('lb4b-token')&&this.storage.get('lb4b-token')!=null) {
      let t = atob(JSON.parse(this.storage.get('lb4b-token'))['access_token'].split('.')[1])
      console.log(JSON.parse(t))
      t = JSON.parse(t)["iss"].split("/")
      console.log(t)
      let token = JSON.parse(this.storage.get('lb4b-token')).access_token
      console.log("GETfactrToken", token)
      const headers = new HttpHeaders({
        'content-type': 'applicaton/json',
        'authorization': 'Bearer ' + token,
        'realmName': t[t.length - 1]
    })
    let options = { headers: headers };
    return this.http.post(`${this.apiURL}/${tableName}/${controlMethod}`, data, options)
  }

    return this.http.get(`${this.apiURL}/${tableName}/${controlMethod}`, { params: data })
  }



}

@Injectable({
  providedIn: 'root'
})
export class PartnerGetFactoryService {
  partnerURL : string = environment.partnerEndPoint;

  constructor(public http: HttpClient) { }
  public customGet(tableName:string, controlMethod:string, data:any){
    return this.http.get(`${this.partnerURL}/${tableName}/${controlMethod}`, {params : data})
  }
}

@Injectable({
  providedIn: 'root'
})
export class ImgMetaGetFactory {
  imgmetaAPI : string = environment.imgmetaEndPoint;

  constructor(public http: HttpClient) { }
  public customGet(tableName:string, controlMethod:string, data:any){
    return this.http.get(`${this.imgmetaAPI}/${tableName}/${controlMethod}`, {params : data})
  }
}

@Injectable({
  providedIn: 'root'
})
export class BillingGetFactoryService {
  billingAPI : string = environment.billingEndPoint;

  constructor(public http: HttpClient) { }
  public customGet(tableName:string, controlMethod:string, data:any){
    return this.http.get(`${this.billingAPI}/${tableName}/${controlMethod}`, {params : data})
  }

  public simpleOneGet(tableName:string, data:any){
    return this.http.get(`${this.billingAPI}/${tableName}/${data}`)
  }
}
