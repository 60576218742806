import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { APIGetFactoryService } from '../../services/get-factory.service';
import * as _ from 'lodash';
@Injectable()
export class MenuService {

    menuItems: Array<any>;
    assignedDBMenu: Array<any>;

    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, private APIGetFactory: APIGetFactoryService) {
        this.menuItems = [];
        this.assignedDBMenu = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        // console.log("this.menuItems is", this.menuItems);
        var decodedTokenObj = this.storage.get('decodedTokenObj');
        if(decodedTokenObj){
            console.log("decodedTokenObj is present");
            var selectQuery = {};
            selectQuery['platform'] = decodedTokenObj.platform;
            selectQuery['userid'] = decodedTokenObj.aaUserObjId;
            selectQuery['roleObjId'] = decodedTokenObj.roleObjId;
            selectQuery['merchantId'] = decodedTokenObj.merchant;
            selectQuery['app'] = "LIVEARf";
            console.log("getting db menu in routes module")
            this.APIGetFactory.customGet('AA_Menu', 'getMenuRoleWise', selectQuery).subscribe((dbMenu)=>{
                console.log("dbMenu in menu service is", dbMenu);
                for(let eachMenuPos in dbMenu){
                    var eachMenuObj = {};
                    if(dbMenu[eachMenuPos]['text'] == "Google Street View Setup"){
                        eachMenuObj['text'] = 'Activation'
                    } else {
                        eachMenuObj['text'] = dbMenu[eachMenuPos]['text'];
                    }
                    eachMenuObj['link'] = dbMenu[eachMenuPos]['link'];
                    eachMenuObj['icon'] = dbMenu[eachMenuPos]['angular2_plus_icon'];
                    eachMenuObj['heading'] = dbMenu[eachMenuPos]['heading'];
                    eachMenuObj['submenu'] = [];
                    for(let eachSubMenuPos in dbMenu[eachMenuPos]['submenu']){
                        var eachSubMenuObj = {};
                        eachSubMenuObj['text'] = dbMenu[eachMenuPos]['submenu'][eachSubMenuPos]['text'];
                        eachSubMenuObj['link'] = dbMenu[eachMenuPos]['submenu'][eachSubMenuPos]['link'];
                        eachSubMenuObj['icon'] = dbMenu[eachMenuPos]['submenu'][eachSubMenuPos]['angular2_plus_icon'];
                        eachSubMenuObj['heading'] = dbMenu[eachMenuPos]['submenu'][eachSubMenuPos]['heading'];
                        eachMenuObj['submenu'].push(eachSubMenuObj);
                    }
                    if(eachMenuObj['submenu'].length == 0){
                        delete eachMenuObj['submenu'];
                    }
                    this.assignedDBMenu.push(eachMenuObj);
                }
                console.log("this.assignedDBMenu is", this.assignedDBMenu);
                // console.log("menu is", menu)
                // menuService.addMenu(this.assignedDBMenu);
                return this.addMenu(this.assignedDBMenu);
                // return abc;
            },function(error){
                console.log("error is", error);
            })
        } else {
            console.log("decodedTokenObj is not present");
            // return this.menuItems;
            return this.menuItems;
        }
        console.log("outside condn", this.menuItems);
            return this.menuItems;
    }

}
