import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { APIGetFactoryService } from '../../../services/get-factory.service';
import { APIPostFactoryService } from '../../../services/post-factory.service';
import { PassparameterService } from '../../../services/passparameter.service';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { GlobalvariableService } from '../../../services/globalvariable.service';
import * as _ from 'lodash';
import * as jwt_decode from 'jwt-decode';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AuthService } from '../../../auth/auth.service';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    valForm: FormGroup;
    passwordForm: FormGroup;
    newUserResponse: any;
    htmlResponse:any;
    regBtnDisabled:boolean;
    checkboxClicked:any;
    isAllDataReceived: boolean = false;
    // toasterconfig: ToasterConfig = new ToasterConfig({
    //     positionClass: 'toast-bottom-right',
    //     showCloseButton: true
    // });

    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, public settings: SettingsService, fb: FormBuilder, private route: ActivatedRoute, private APIGetFactory: APIGetFactoryService, private APIPostFactory: APIPostFactoryService, public passparameter: PassparameterService, public router:Router, public globalVariable: GlobalvariableService, private snackBar: MatSnackBar, private authService: AuthService) {

        let password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
        let certainPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);

        this.passwordForm = fb.group({
            'password': password,
            'confirmPassword': certainPassword
        });

        this.valForm = fb.group({
            'account_org_name': [null, Validators.required],
            'account_phone': [null, Validators.required],
            'account_website': [null, Validators.required],
            'account_email': [null, Validators.required],
            'account_agreed':[false, Validators.requiredTrue],
            'news_subscribed':[false],
            'verify_code':[false, Validators.requiredTrue]
        });
    }

    submitForm($ev, value: any) {
        console.log("this.valForm is", this.valForm);
        console.log("$ev is", $ev);
        console.log("value is", value);
        value.orgName = value.account_org_name;
        value.orgPhone = value.account_phone;
        value.orgWebAddress = value.account_website;
        value.orgEmail = value.account_email;
        value.agreed = value.account_agreed;
        value.verifyCode = value.account_agreed;
        this.register(value, this.valForm)
    }
    

    ngOnInit() {
        console.log("this.globalVariable.unregisteredMerchant is", this.globalVariable.unregisteredMerchant);
        var unregisteredMerchant = [{}];
        unregisteredMerchant = this.globalVariable.unregisteredMerchant;
        // console.log("this.storage.get('') is", this.storage.get(''));
        // var unregisteredMerchant = this.storage.get('unregisteredMerchant');
        console.log(" unregisteredMerchant is", unregisteredMerchant);
        if(unregisteredMerchant && !_.isEmpty(unregisteredMerchant)){
            this.newUserResponse = unregisteredMerchant[0];
            this.isAllDataReceived = true;
            console.log("this.newUserResponse in if is", this.newUserResponse);
            this.passMerchantValues(this.newUserResponse);
        } else {
            console.log("query params are", this.route.snapshot.queryParamMap);
            var code = this.route.snapshot.queryParamMap['params']['code'];
            var context = this.route.snapshot.queryParamMap['params']['context'];
            var scope = this.route.snapshot.queryParamMap['params']['scope'];
            var appname = this.route.snapshot.queryParamMap['params']['appname'];
            var buttonDisabled = false;
            var data = {
                "code": code,
                "context": context,
                "scope": scope,
                "appname": appname
            };
            console.log("data from registercomp line 97 ", data);
            this.checkRegisteredApp(data);
        }
    }
    checkRegisteredApp(data){
        console.log("data in checkRegisteredApp is", data);
        this.APIGetFactory.customGet('LB4_Merchant', 'registerApp', data).subscribe(new_user_response=>{
            // var merchantDetails;
            this.isAllDataReceived = true;
            this.authService.isLoggedInSuccess = true;
            var merchantDetails = new_user_response;
            console.log('The New User Response is ::', merchantDetails);
            this.newUserResponse = new_user_response[0];
            console.log("Response from Server for User ", this.newUserResponse);
            var contextp = this.newUserResponse['context'];
            var scopep = this.newUserResponse['scope'];
            var user_idp = this.newUserResponse['user_id']; //$scope.newUserResponse.user.id;
            this.passMerchantValues(this.newUserResponse);
        },function(error){
            console.log("error is", error);
            if (error.status == 400) {
                console.log("Error Reason", error.data.error);
            } else if(error.data.status == 'Merchant should complete register'){
                this.authService.isLoggedInSuccess = true;
                this.newUserResponse = error.data.merchant;
                console.log("this.newUserResponse is", this.newUserResponse);
                this.passMerchantValues(this.newUserResponse);
            }
        })
    }
    passMerchantValues(merchantInfo) {
        this.storage.set('',{
            newMerchantID: merchantInfo.id,
            storeHash: merchantInfo.store_hash,
            platform: merchantInfo.platform,
            formattedAddress: merchantInfo.address,
            appname: merchantInfo.appname
        })
        // _.extend({}, setValue);
    };
    register (accountData, controllerObj) {
        this.regBtnDisabled = true;
        this.htmlResponse = accountData;
        console.log('submitForm is reached ', accountData);
        console.log('submitForm is reached controllerObj ', controllerObj);
        console.log("controllerObj.valid", controllerObj.valid);
        console.log("controllerObj.status", controllerObj.status);
        console.log("this.storage.get('') is", this.storage.get('unregisteredMerchant'));
        //controllerObj.status = "VALID";
        if (controllerObj.status == 'VALID') {
            console.log('submitForm is valid');
            // this.checkboxClicked['noAccountAgreed'] = false;
            // this.checkboxClicked['noVerifyCode'] = false;
            //accountData.account_agreed = true;
            if (accountData.account_agreed) {
                console.log('accountData.agreed ');
                this.createNewMerchantLogin(accountData, controllerObj, this.newUserResponse, 'app.fixuninstalldata', 'app.activation');
            }
        } else {
            this.regBtnDisabled = false;
            console.log('Not valid!!', accountData);
            if (!accountData.account_agreed && !accountData.verify_code) {
                // this.checkboxClicked['noAccountAgreed'] = true;
                // this.checkboxClicked['noVerifyCode'] = true;
                this.snackBar.open('Agree for Terms & Allow verification codes to reach your email!', 'dismiss');
            } else if (!accountData.verify_code) {
                // this.checkboxClicked['noVerifyCode'] = true;
                // this.checkboxClicked['noAccountAgreed'] = false;
                this.snackBar.open('Allow verification codes to reach your email!', 'dismiss');
            } else if (!accountData.account_agreed) {
                // this.checkboxClicked['noVerifyCode'] = false;
                // this.checkboxClicked['noAccountAgreed'] = true;
                this.snackBar.open('Agree for Terms', 'dismiss');
            }
        }
    }
    createNewMerchantLogin(merchant, controllerObj, newUserResponse, fixuninstalldata, activation) {
        console.log("in createNewMerchnatLogin ");
        this.htmlResponse = controllerObj;
        // var toaster = this.toaster;
        this.htmlResponse.buttonDisabled = true;
        // LoadingIcon.iconLoadingStart('inLogin');
        var receivedParameters = this.storage.get('');
        var loginData = {};

        merchant.merchantId = receivedParameters['newMerchantID'];
        merchant.formattedAddress = receivedParameters['formattedAddress'];
        merchant.storeHash = loginData['storeHash'] = receivedParameters['storeHash'];
        merchant.appname = loginData['appname'] = receivedParameters['appname'];

        loginData['email'] = merchant.account_email;
        // raj on 15 JAN 16 . Arrangement for not asking email for the Store Owner again and again.
        merchant.perEmail = merchant.account_email;
        loginData['password'] = merchant.password;
        loginData['merchant'] = receivedParameters['newMerchantID'];
        loginData['isAgreed'] = merchant.account_agreed;
        loginData['isSubscribed'] = merchant.news_subscribed;
        loginData['sendVerificationCode'] = merchant.verify_code;

        console.log(" this.htmlResponse.createNewMerchantLogin merchant ", merchant, " loginData ", loginData, 'newUserResponse', newUserResponse);

        this.APIPostFactory.customPost('Auth', 'register', loginData).subscribe(response=> {
            console.log('Reponse after uploding auth/register', response);
            this.authService.isLoggedInSuccess = true;
            var userInfo = response['userInfo'];
            var activeMerchants = response['merchants'];

            merchant.aa_user_id = userInfo.id; //response.id;
            var merchantData = {
                merchantData: merchant,
                newUserResponse: newUserResponse
            };
            this.getMerchantData(activeMerchants, merchantData, merchant);
        }, function(error) {
            console.log('In then Error -bigcommerceRegisterController : ', error);
            // LoadingIcon.iconLoadingStop();
            if (error.status === 400 && error.data === "User already exists"){
                this.snackBar.open('Please Contact Admin', 'dismiss');
            }
            else{
                this.snackBar.open('Please Contact Admin', 'dismiss');
            }
        });
    }
    getMerchantData(activeMerchants, merchantData, merchant){
        console.log("activeMerchants in getMerchantData is", activeMerchants);
        var globalStorage = this.storage;
        // var toaster = this.toaster;
        this.APIPostFactory.customPost('LB4_Merchant', 'createMerchantRegister', merchantData).subscribe(async response=> {
            this.authService.isLoggedInSuccess = true;
            // this.regBtnDisabled = false;
            console.log('createMerchantRegister response ', response);


            // LoadingIcon.iconLoadingStop();
            this.storage.set('',{
                userEmail: merchant.orgEmail
            });
            var jwtToken = response[5].jwt.token;
            localStorage.setItem('jwt', jwtToken);
            var decodedToken = jwt_decode(jwtToken);
            this.storage.set('decodedTokenObj', decodedToken);
            console.log('The JWT Token Generated is :', jwtToken);
            await this.APIGetFactory.customGet('LB4_Merchant', 'getKcTokenMerchant', { merchantId: decodedToken.merchant }).subscribe(data => {
                console.log("live-sec data from lb4b-api:", data);
                let kc_tokens = JSON.parse(JSON.stringify(data));
                globalStorage.set('lb4b-token', JSON.stringify(data));
                console.log("kc tokens:", kc_tokens);
                setInterval(async () => {
                    var toBackend = {
                        access_token: JSON.parse(this.storage.get('lb4b-token')).access_token,
                        refresh_token: JSON.parse(this.storage.get('lb4b-token')).refresh_token
                    }
                    await this.APIGetFactory.customGet('LB4_Merchant', 'refreshKcToken', toBackend).subscribe(async data => {
                        console.log("refresj live-sec data from lb4b-api:", data);
                        console.log("before refresh", JSON.parse(globalStorage.get("lb4b-token")))
                        globalStorage.set('lb4b-token', JSON.stringify(data));
                        console.log("After refresh", JSON.parse(globalStorage.get("lb4b-token")))
                    })
                }, 280 * 1000);
                if (activeMerchants.length > 1) {
                    var merchantsList = {
                        merchantsList: activeMerchants
                    }
                    this.storage.set('', merchantsList);
                    // this.globalVariable.merchantsList = merchantsList.merchantsList;
                    this.router.navigate(['fixuninstall/fixuninstall'])// $state.go(fixuninstalldata);
                } else {
                    console.log('activeMerchants', activeMerchants);
                    this.router.navigate(['setup/activation'])
                }
            })
            this.snackBar.open('Merchant Registered successfully', 'dismiss');
        }, function(error) {
            // login -- false
            this.snackBar.open('Please Contact Admin', 'dismiss');
        });
    }

}
