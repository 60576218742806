import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class APIPostFactoryService {

	apiURL :string = environment.apiEndPoint;

	constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService) { }

	public customPost(tableName: string, controlMethod: string, data: any) {
		console.log("tableName is", tableName);
		console.log("controlMethod is", controlMethod);
		console.log("data is", data);
		if (this.storage.get('lb4b-token')&&this.storage.get('lb4b-token')!=null) {
			console.log("inside get token from local storage to pass it as a header...")
			let t = atob(JSON.parse(this.storage.get('lb4b-token'))['access_token'].split('.')[1])
			console.log(JSON.parse(t))
			t = JSON.parse(t)["iss"].split("/")
			console.log(t)
			let token = JSON.parse(this.storage.get('lb4b-token')).access_token
			console.log("kc_acc_tok-from_storage:", token);
			const headers = new HttpHeaders({
				'content-type': 'applicaton/json',
				'authorization': 'Bearer ' + token,
				'realmName': t[t.length - 1]
			})
			let options = { headers: headers };
			return this.http.post(`${this.apiURL}/${tableName}/${controlMethod}`, data, options)
			// const headers = new HttpHeaders()
			//   .set('authorization', 'Bearer ' + token)
			//   .set('realmName', t[t.length - 1]);
			//   console.log("post-factory headers line no.31:",headers);
			// return this.http.post(`${this.apiURL}/${tableName}/${controlMethod}`, { params: data, headers })
		}
		return this.http.post(`${this.apiURL}/${tableName}/${controlMethod}`, data)
	}

}


@Injectable({
	providedIn: 'root'
})
export class PartnerPostFactoryService {
	partnerURL : string = environment.partnerEndPoint;

	constructor(public http: HttpClient) { }
	public customPost(tableName:string, controlMethod:string, data:any){
	  console.log("Partner Post tableName is", tableName);
	  console.log("Partner Post controlMethod is", controlMethod);
	  console.log("Partner Post data is", data);
	  // return {status:'ok'};
	  return this.http.post(`${this.partnerURL}/${tableName}/${controlMethod}`, data)
	}
  }

@Injectable({
	providedIn: 'root'
})
export class ImgMetaPostFactory {
	imgmetaAPI : string = environment.imgmetaEndPoint;

	constructor(public http: HttpClient) { }
	public customPost(tableName:string, controlMethod:string, data:any){
		return this.http.post(`${this.imgmetaAPI}/${tableName}/${controlMethod}`, data)
	}
}

@Injectable({
	providedIn: 'root'
})
export class BillingPostFactoryService {
	billingAPI : string = environment.billingEndPoint;

	constructor(public http: HttpClient) { }
	public customPost(tableName:string, controlMethod:string, data:any){
		return this.http.post(`${this.billingAPI}/${tableName}/${controlMethod}`, data)
	}
}
